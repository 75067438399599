import { MouseEvent, useState } from "react";
import { Box, Button, Popover, Stack } from "@mui/material";
import { Menu } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import { useUser } from "../../providers/UserProvider";
import { navigationData } from "./constants";

const MobileMenuBtn = () => {
  const navigate = useNavigate();
  const { user } = useUser();

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLDivElement>) => setAnchorEl(event.currentTarget);

  const open = Boolean(anchorEl);
  const id = open ? "menu-popover" : undefined;

  return (
    <>
      <Stack
        direction="row"
        sx={{
          borderRadius: "50%",
          backgroundColor: "#B41601",
          height: 36,
          width: 36,
          alignItems: "center",
          justifyContent: "space-around",
        }}
        onClick={handleClick}
      >
        <Menu sx={{ color: "#fff", fontSize: "29.25px" }} />
      </Stack>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{ marginTop: "13px", ".MuiPaper-root": { borderRadius: "8px" } }}
      >
        <Box sx={{ width: "100%", minWidth: "220px" }}>
          {navigationData
            .filter(({ expect }) => !expect?.some((e) => e === user.countries[0]))
            .map(({ label, path }) => {
              return (
                <Box key={label}>
                  <Button variant="customButton" onClick={() => navigate(path)}>
                    {label}
                  </Button>
                </Box>
              );
            })}
        </Box>
      </Popover>
    </>
  );
};

export default MobileMenuBtn;
