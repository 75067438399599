import { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";

import { AlertTypeEnum, useAlert } from "../../../providers/AlertProvider";
import { useUser } from "../../../providers/UserProvider";
import { postApi } from "../../../service";
import TrendInfoCard from "../../common/card/TrendInfoCard";
import { IWatchForm } from "../productInsights";

const OpenPayments = ({ onSearch, formData }: { onSearch: boolean; formData: IWatchForm }) => {
  const { control } = useForm();
  const watchForm = useWatch({ control });
  const [totalNumberOfOpenPayments, setTotalNumberOfOpenPayments] = useState<{
    totalNumberOfOpenPayments: string;
  } | null>(null);
  const { addAlert } = useAlert();
  const { user } = useUser();

  const dataFetch = async () => {
    try {
      let res = await postApi(`${process.env.REACT_APP_POST}`, {
        api_name: "InvoiceSightForNumberOfOpenPayments",
        org: process.env.REACT_APP_ORG,
        sector: process.env.REACT_APP_SECTOR,
        countries: user.countries,
        ...formData,
      });

      const { data, status, message } = res.data;

      if (!status) {
        setTotalNumberOfOpenPayments(null);
        // addAlert({ message, type: AlertTypeEnum.ERROR });
      } else {
        setTotalNumberOfOpenPayments(data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    dataFetch();
  }, [watchForm, onSearch]);

  return (
    <TrendInfoCard
      isTrendingUp={true}
      content="Number of Open payments"
      value={totalNumberOfOpenPayments?.totalNumberOfOpenPayments ?? "0"}
    />
  );
};

export default OpenPayments;
