import { MouseEvent, useState } from "react";
import { Box, Button, Divider, Popover, Stack } from "@mui/material";
import { PersonOutlineOutlined } from "@mui/icons-material";

const MobileUserSettings = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLDivElement>) => setAnchorEl(event.currentTarget);

  const removeToken = () => {
    localStorage.removeItem("auth");
    window.location.reload();
  };

  const open = Boolean(anchorEl);
  const id = open ? "user-popover" : undefined;

  return (
    <>
      <Stack
        direction="row"
        sx={{
          borderRadius: "50%",
          backgroundColor: "#B41601",
          height: 36,
          width: 36,
          alignItems: "center",
          justifyContent: "space-around",
        }}
        onClick={handleClick}
      >
        <PersonOutlineOutlined sx={{ color: "#fff", fontSize: "29.25px" }} />
      </Stack>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{ marginTop: "13px", ".MuiPaper-root": { borderRadius: "8px" } }}
      >
        <Box sx={{ width: "100%", minWidth: "220px" }}>
          <Divider sx={{ margin: "0px 12px" }} />
          <Button variant="customButton" onClick={removeToken}>
            Sign out
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default MobileUserSettings;
