/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";

import { AlertTypeEnum, useAlert } from "../../../providers/AlertProvider";
import { useUser } from "../../../providers/UserProvider";
import { postApi } from "../../../service";
import Loader from "../../common/loader/Loader";
import { countryDetails } from "../../common/constants/countries";

interface IOrderLimitData {
  referenceValue: number;
  noOfDocuments: number;
  totalNetValue: number;
}

const OrderLimit = ({ formData, onSearch }) => {
  const { user } = useUser();
  const { addAlert } = useAlert();
  const currency = countryDetails[user.countries[0]].currency;

  const [resData, setResData] = useState<IOrderLimitData[]>();
  const [isLoading, setIsLoading] = useState(true);

  const dataFetch = async (value): Promise<IOrderLimitData> => {
    try {
      setIsLoading(true);
      const res = await postApi(`${process.env.REACT_APP_POST}`, {
        api_name: "OrderInsightByNetValueRange",
        org: process.env.REACT_APP_ORG,
        sector: process.env.REACT_APP_SECTOR,
        referenceValue: { op: "less", value },
        countries: user.countries,
        ...formData,
      });

      const { data, status, message } = res.data;
      setIsLoading(false);

      if (!status) {
        // if (formData.duration !== "1 Month")
          addAlert({ message, type: AlertTypeEnum.ERROR });
      } else {
        
        return { ...data, referenceValue: value };
      }
    } catch (err) {
      setIsLoading(false);
      console.error(err);
    }
    return { referenceValue: 0, noOfDocuments: 0, totalNetValue: 0 };
  };

  useEffect(() => {
    (async () => {
      const results = await Promise.all([dataFetch(250), dataFetch(500)]);
      const data = results.filter((item) => item.referenceValue > 0);
      if (data.length === 2)
        setResData([
          data[0],
          {
            ...results[1],
            noOfDocuments: results[0].noOfDocuments
              ? results[1].noOfDocuments - results[0].noOfDocuments
              : results[1].noOfDocuments,
            totalNetValue: results[0].totalNetValue
              ? results[1].totalNetValue - results[0].totalNetValue
              : results[1].totalNetValue,
          },
        ]);
    })();
  }, [onSearch]);

  return (
    <>
      <Typography
        sx={{ flex: 1 }}
        my={2}
        variant="h2"
        color="#000"
        textTransform={"capitalize"}>
        Order Limit Insights for {currency}250 and {currency}500
      </Typography>

      <Stack direction="row" justifyContent="space-around" spacing={2}>
        {isLoading ? (
          <Loader />
        ) : (
          resData &&
          resData.map((item, index) => (
            <Fragment key={index}>
              {index ? (
                <Divider orientation="vertical" variant="middle" flexItem />
              ) : (
                <></>
              )}
              <Box>
                <Typography align="center">
                  No. Orders placed Under {item.referenceValue}
                </Typography>
                <Typography variant="h3" align="center" m={2}>
                  {item.noOfDocuments}
                </Typography>
              </Box>
              <Divider orientation="vertical" variant="middle" flexItem />
              <Box>
                <Typography align="center">
                  Orders value placed under {currency}
                  {item.referenceValue}
                </Typography>
                <Typography variant="h3" align="center" m={2}>
                  {currency}
                  {item.totalNetValue}
                </Typography>
              </Box>
            </Fragment>
          ))
        )}
      </Stack>
    </>
  );
};

export default OrderLimit;
