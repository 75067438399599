import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';



import { ChartData } from "chart.js";

import { IChartData, IModifyBarChartData } from "./types";
import { months } from "../constants/basic";

dayjs.extend(utc)
// chart label generate
const generateChartLabel = (cv: any, dayType: string): string => {
  const { month, day } = cv;

  switch (dayType) {
    case "week":
      return day ? dayjs.utc(day).format("DD/MM/YYYY") : "";

    case "today":
      return day ? dayjs.utc(day).format("DD/MM/YYYY") : "";

    case "month":
    case "threeMonths":
      return month ? months[month - 1] : "";

    default:
      return cv[dayType] ?? "";
  }
};

export const modifyBarChartData = ({
  data,
  dataSet,
  dayType,
  dataKey,
  backgroundColor,
}: IModifyBarChartData): ChartData => {
  return data.reduce(
    (ac: IChartData, cv: { [key: string]: any }) => {
      ac.labels = [...ac.labels, generateChartLabel(cv, dayType)];

      const datasets = dataKey.map((ele, index) => {
        const a = cv[ele] || 0;
        return {
          ...ac.datasets[index],
          ...dataSet,
          label: ele,
          data: ac.datasets[index]?.data ? [...ac.datasets[index].data, a] : [a],
          backgroundColor: backgroundColor[index],
        };
      });

      ac.datasets = datasets;

      return ac;
    },
    { labels: [], datasets: [] }
  );
};
