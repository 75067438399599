import { useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { Grid, IconButton } from "@mui/material";
import { FilterAltOffOutlined, FilterAltOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

import InsightFilter from "../InsightFilter";
import BasicHeader from "../../common/BasicHeader";
import { IWatchForm } from "../productInsights";
import AverageOrderQuantity from "./AverageOrderQuantity";
import AverageOrderValue from "./AverageOrderValue";
import { useUser } from "../../../providers/UserProvider";

const CustomerInsights = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const formDetails = useForm({
    defaultValues: {
      duration: "1 Month",
      soldTos: user.soldToDetails ? user.soldToDetails.map(({ accountId }) => accountId) : [],
    },
  });
  const watchForm: IWatchForm = useWatch({ control: formDetails.control });

  const [onSearch, setOnSearch] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState<boolean>(false);

  const toggleSearch = () => {
    setOnSearch(!onSearch);
  };
  const loadDefaultValues = (res) => {
    if (
      res.data.data.noOfDocuments === 0 &&
      watchForm.duration === "1 Month" &&
      !watchForm.franchises &&
      !watchForm.soldTos
    ) {
      formDetails.setValue("duration", "3 Month");
      toggleSearch();
      return true;
    }
    return false;
  };

  return (
    <>
      <BasicHeader
        title={t("CUSTOMER_INSIGHT")}
        customStyle={{ pb: "20px" }}
        children={
          <IconButton
            aria-label="filter"
            onClick={() => setIsFilterVisible((prev) => !prev)}
            sx={{ display: { xs: "inherit", sm: "none" } }}
          >
            {isFilterVisible ? <FilterAltOffOutlined /> : <FilterAltOutlined color="primary" />}
          </IconButton>
        }
      />
      {!isFilterVisible && <InsightFilter toggleSearch={toggleSearch} formDetails={formDetails} formData={watchForm} />}

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <AverageOrderQuantity onSearch={onSearch} formData={watchForm} loadDefaultValues={loadDefaultValues} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AverageOrderValue onSearch={onSearch} formData={watchForm} />
        </Grid>
      </Grid>
    </>
  );
};

export default CustomerInsights;
