import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import { currencyFormatter } from "../../helper";
import { chartColors } from "../common/chart/constants";

dayjs.extend(utc);
export const insightTabs = (t) => [
  t("MARKET_INSIGHT"),
  t("ORDER_INSIGHTS"),
  t("PRODUCT_INSIGHTS"),
  t("CUSTOMER_INSIGHT"),
  "Invoice Insights",
];

export const optionsWithCurrency = (currency: string, data: any[], dateFormat: string) => ({
  plugins: {
    legend: { display: false },
    tooltip: {
      callbacks: {
        title: (a) =>
          dayjs.utc(data[a[0].dataIndex].startDate).format(dateFormat) +
          " - " +
          dayjs.utc(data[a[0].dataIndex].endDate).format(dateFormat),
      },
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: { grid: { display: true } },
    y: {
      min: 0,
      ticks: { callback: (value: string) => currency + " " + currencyFormatter(Number(value)) },
    },
  },
});

export const options = {
  plugins: { legend: { display: false }, tooltip: { callbacks: {} } },

  responsive: true,
  maintainAspectRatio: false,
  scales: { x: { grid: { display: true } }, y: { min: 0, ticks: { min: 0, stepSize: 1 } } },
};

export const orderInsightPieOptions = {
  responsive: true,
  elements: { arc: { borderWidth: 0, hoverOffset: 20 } },
  layout: { padding: { left: 20 } },
};

export const orderInsightPlugins = (abc: string, isMobileView: boolean) => ({
  plugins: {
    legend: {
      ...(isMobileView
        ? { position: "bottom", align: "start" }
        : { position: "right", align: "center", labels: { padding: 20 } }),
    },

    tooltip: {
      callbacks: {
        label: (tooltipItem: any): string => {
          return `${abc}${tooltipItem.formattedValue}`;
        },
      },
    },
  },
});

export const orderInsightPieData = {
  maintainAspectRatio: false,
  responsive: false,
  datasets: [{ backgroundColor: chartColors, hoverBackgroundColor: chartColors }],
};
export const tableHeadSx = { padding: "5px 10px", border: "1px solid #ccc", background: "#f2f9fb", fontWeight: "bold" };
export const tableBodySx = { padding: "5px 10px", border: "1px solid #ccc" };
export const btnPrimary = {
  padding: "6px 27px 5px 27px",
  borderRadius: "24px",
  height: "fit-content",
  mt: { xs: 0, sm: 3 },
  boxShadow: "none",
  "&:hover": {
    backgroundColor: "#025569 ",
    boxShadow: "none",
  },
};

export const btnSec = {
  padding: "6px 27px 5px 27px",
  color: "#2a9fd8",
  borderRadius: "24px",
  border: "1px solid #eee",
  height: "fit-content",
  mt: { xs: 0, sm: 3 },
  "&:hover": {
    backgroundColor: "#fff",
    border: "1px solid #0a8caa",
  },
};
