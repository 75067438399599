/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { useAlert, AlertTypeEnum } from "../../providers/AlertProvider";
import { useUser } from "../../providers/UserProvider";
import { postApi } from "../../service";
import Loader from "../common/loader/Loader";
import { countryDetails } from "../common/constants/countries";
import { tableHeadSx, tableBodySx } from "../insightsv2/constants";
import { currencyFormatter } from "../../helper";

const OrderInsightCard = ({ onSearch, formData }) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { addAlert } = useAlert();
  const currency = countryDetails[user.countries[0]].currency;

  const [resData, setResData] =
    useState<
      { franchiseName: string; netValue: string; franchiseCode: string }[]
    >();
  const [isLoading, setIsLoading] = useState(true);

  const handleSearch = async () => {
    try {
      setIsLoading(true);
      let arr = await postApi(`${process.env.REACT_APP_POST}`, {
        api_name: "HomeInsightBySaleForFranchise",
        org: process.env.REACT_APP_ORG,
        sector: process.env.REACT_APP_SECTOR,
        countries: user.countries,
        ...formData,
      });
      
      const { data, status, message } = arr.data;
      if (!status) {
        setResData([]);
        // if (formData.duration !== "1 Month") addAlert({ message, type: AlertTypeEnum.ERROR });
        if (
          formData.duration === "custom" &&
          ((!formData.startDate || !formData.endDate) || (new Date(formData.startDate)> new Date(formData.endDate)))
        ) {
          addAlert({
            message: "Error in start date or end date!",
            type: AlertTypeEnum.ERROR,
          });
        }
      } else {
        setResData(data);
      }
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    handleSearch();
  }, [onSearch]);

  return (
    <Box flex={1} sx={{ mt: 2 }}>
      <Typography
        variant="h2"
        children={t("TOP_REVENUE_GENERATING_FRANCHISES")}
      />

      {isLoading ? (
        <Loader />
      ) : (
        <>
          <TableContainer sx={{ width: "100%", mt: 2 }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ ...tableHeadSx, minWidth: 80 }} align="left">
                    {t("NO")}
                  </TableCell>
                  <TableCell sx={tableHeadSx} align="left">
                    {t("FRANCHISE")}
                  </TableCell>
                  <TableCell sx={tableHeadSx} align="left">
                    {t("VALUE")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {resData &&
                  resData.map((item, index) => (
                    <TableRow key={item.franchiseName}>
                      <TableCell
                        sx={tableBodySx}
                        align="left"
                        component="th"
                        scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell sx={tableBodySx} align="left">
                        {item.franchiseName}({item.franchiseCode})
                      </TableCell>
                      <TableCell sx={tableBodySx} align="left">
                        {currency} {currencyFormatter(Number(item.netValue))}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Box>
  );
};

export default OrderInsightCard;
