/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Box, ClickAwayListener, Divider, List, ListItemButton, Typography } from "@mui/material";
import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import InputFilters, { filterData } from "../InputFilters";
import { filterLayout } from "../constants/inputs";
import { CustomDateSelectButton } from "./styles";
import { ExtendedSearchFilterOptions } from "../types";

interface IDateSelectProps {
  formDetails: UseFormReturn<{ startDate?: Date; endDate?: Date; duration?: string }, any, undefined>;
  isClear?: boolean;
  duration?: string;
  dateOptions: { label: string; value: string }[];
}

const DateSelect: React.FC<IDateSelectProps> = ({ formDetails, isClear, duration, dateOptions }) => {
  const { t } = useTranslation();
  const { getValues, setValue } = formDetails;
  const [openList, setOpenList] = useState(false);
  const [openCustomDate, setOpenCustomDate] = useState(false);
  const [filter, setFilter] = useState<ExtendedSearchFilterOptions[]>([]);
  const [selectedDateType, setSelectedDateType] = useState(t("ONE_MONTH"));
  const [clearState, setClearState] = useState(isClear);

  useEffect(() => setFilter(filterData({ filterLayout: filterLayout(t), setValue, getValues })), []);
  // case where default value for 1 month is empty
  useEffect(() => {
    if (duration === "3 Month") {
      setSelectedDateType(t("THREE_MONTH"));
    }
  }, [duration]);
  useEffect(() => {
    if (clearState !== isClear) {
      setSelectedDateType(t("ONE_MONTH"));
      setOpenList(false);
      setOpenCustomDate(false);
      setClearState(!clearState);
    }
  }, [isClear]);

  const handleClose = ({ label, value }) => {
    setValue("duration", value);
    setSelectedDateType(label);
    setOpenList(false);
    setOpenCustomDate(false);
  };

  const handleCustomButton = () => {
    setValue("duration", "custom");
    setSelectedDateType(t("CUSTOM"));
    setOpenCustomDate(true);
    setOpenList(false);
  };

  return (
    <>
      <ClickAwayListener onClickAway={() => setOpenList(false)}>
        <Box sx={{ position: "relative" }}>
          <Typography variant="h3">{t("DURATION")}</Typography>
          <CustomDateSelectButton
            id="select-customized-button"
            aria-controls={openList ? "select-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openList ? "true" : undefined}
            variant="outlined"
            disableElevation
            onClick={() => setOpenList((prev) => !prev)}
            sx={{ color: selectedDateType ? "#0C8CAA" : "#63666A", width: { xs: 1, sm: "160px" } }}
          >
            {selectedDateType || "Select Date"}{" "}
            <KeyboardArrowDownOutlined sx={{ position: "relative", right: "-24px" }} />
          </CustomDateSelectButton>
          {openList && (
            <List
              sx={{
                width: 160,
                maxWidth: 360,
                bgcolor: "background.paper",
                position: "absolute",
                border: "1px #D8D8D8 solid",
                borderRadius: "8px",
                zIndex: 1,
                padding: "10px",
              }}
              aria-labelledby="nested-list-subheader"
            >
              {dateOptions.map((e) => (
                <ListItemButton onClick={() => handleClose(e)} disableRipple key={e.value}>
                  {e.label}
                </ListItemButton>
              ))}

              <Divider sx={{ my: 0.5 }} />

              <ListItemButton onClick={handleCustomButton} disableRipple>
                {t("CUSTOM")}
              </ListItemButton>
            </List>
          )}
        </Box>
      </ClickAwayListener>

      {openCustomDate && <InputFilters customStyle={{ p: 0 }} filter={filter} getValues={getValues} component="div" />}
    </>
  );
};

export default DateSelect;
