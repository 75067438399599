import { useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { Grid, IconButton, Typography } from "@mui/material";
import { FilterAltOffOutlined, FilterAltOutlined } from "@mui/icons-material";

import InsightFilter from "../InsightFilter";
import BasicHeader from "../../common/BasicHeader";
import { IWatchForm } from "../productInsights";
import DuePayments from "./DuePayments";
import OpenPayments from "./OpenPayments";
import AveragePayments from "./AveragePayments";
import InvoiceChart from "./InvoiceChart";
import MaxTurnaround from "./MaxTurnaround";

const InvoiceInsights = () => {
  const formDetails = useForm({ defaultValues: { duration: "1 Month" } });
  const { control } = formDetails;
  const watchForm: IWatchForm = useWatch({ control });

  const [onSearch, setOnSearch] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState<boolean>(false);

  const toggleSearch = () => setOnSearch(!onSearch);

  return (
    <>
      <BasicHeader
        title={"Invoice Insights"}
        customStyle={{ pb: "20px" }}
        children={
          <IconButton
            aria-label="filter"
            onClick={() => setIsFilterVisible((prev) => !prev)}
            sx={{ display: { xs: "inherit", sm: "none" } }}
          >
            {isFilterVisible ? <FilterAltOffOutlined /> : <FilterAltOutlined color="primary" />}
          </IconButton>
        }
      />
      {!isFilterVisible && <InsightFilter toggleSearch={toggleSearch} formDetails={formDetails} formData={watchForm} />}

      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <DuePayments onSearch={onSearch} formData={watchForm} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <OpenPayments onSearch={onSearch} formData={watchForm} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <AveragePayments onSearch={onSearch} formData={watchForm} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MaxTurnaround onSearch={onSearch} formData={watchForm} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h2" sx={{ ml: 4, mb: 2 }}>
            Total Invoiced Value Pattern
          </Typography>
          <InvoiceChart onSearch={onSearch} formData={watchForm} />
        </Grid>
      </Grid>
    </>
  );
};

export default InvoiceInsights;
