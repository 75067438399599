import { SyntheticEvent, useState } from "react";
import { Box, Button, ClickAwayListener, Tab, Tabs } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import DelimitBox from "../common/DelimitBox";
import { insightTabs } from "./constants";
import ProductInsights from "./productInsights";
import OrderInsights from "./orderInsights";
import CustomerInsights from "./customerInsights";
import MarketInsights from "./marketInsights";
import { useUser } from "../../providers/UserProvider";
import { countryDetails } from "../common/constants/countries";
import InvoiceInsights from "./invoiceInsights";
import { KeyboardArrowDown } from "@mui/icons-material";
import { useWindowDimensions } from "../../hooks";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = ({ children, value, index, ...rest }: TabPanelProps) => (
  <Box
    role="tabpanel"
    hidden={value !== index}
    id={`vertical-tabpanel-${index}`}
    aria-labelledby={`vertical-tab-${index}`}
    {...rest}
    sx={{ p: { xs: 0, sm: 3 }, width: 1 }}
  >
    {value === index && children}
  </Box>
);

const InsightsV2 = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const { user } = useUser();
  const { windowWidth } = useWindowDimensions();
  const [tabIndex, setTabIndex] = useState(state?.tab ?? 0);
  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(true);

  return (
    <Box sx={{ p: 0, position: "relative" }}>
      {windowWidth < 600 && (
        <ClickAwayListener onClickAway={() => setIsMenuVisible(false)}>
          <Box sx={{ display: "inline-block" }}>
            <Button
              sx={{
                bgcolor: "background.paper",
                "&:hover": {
                  bgcolor: "background.paper",
                },
              }}
              endIcon={<KeyboardArrowDown />}
              onClick={() => setIsMenuVisible((prev) => !prev)}
            >
              Insight Menu
            </Button>

            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={tabIndex}
              onChange={(_e: SyntheticEvent, val: number) => {
                setTabIndex(val);
                setIsMenuVisible(false);
              }}
              sx={{
                minWidth: "15vw",
                bgcolor: "background.paper",
                border: { xs: 1, sm: 0 },
                borderRight: { xs: 1, sm: 1 },
                borderColor: { xs: "#D8D8D8", sm: "divider" },
                position: { xs: "absolute", sm: "initial" },
                top: 38,
                left: 0,
                borderRadius: { xs: 2, sm: 0 },
                overflow: "hidden",
                zIndex: 1,
                ...(!isMenuVisible && { display: "none" }),
                boxShadow: { xs: `0px 4px 12px #00000033`, sm: "none" },
              }}
            >
              {insightTabs(t)
                .filter((_ele, index) => !(user.countries[0] !== countryDetails.SG.countryCode && index === 4))
                .map((e, i) => (
                  <Tab
                    label={e}
                    key={e}
                    id={`vertical-tab-${i}`}
                    sx={{ alignItems: "start", "&:hover": { bgcolor: `rgba(0, 0, 0, 0.04)` } }}
                    aria-controls={`vertical-tabpanel-${i}`}
                  />
                ))}
            </Tabs>
          </Box>
        </ClickAwayListener>
      )}

      <DelimitBox customStyle={{ width: { xs: `calc(100vw - 32px)`, sm: 1 }, display: "flex" }}>
        {windowWidth >= 600 && (
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={tabIndex}
            onChange={(_e: SyntheticEvent, val: number) => {
              setTabIndex(val);
            }}
            sx={{
              minWidth: "15vw",
              bgcolor: "background.paper",
              borderRight: 1,
              borderColor: "divider",
              position: "initial",
              top: 0,
              left: 0,
              borderRadius: { xs: 2, sm: 0 },
              overflow: "hidden",
              zIndex: 1,
            }}
          >
            {insightTabs(t)
              .map((e, i) => (
                <Tab
                  label={e}
                  key={e}
                  id={`vertical-tab-${i}`}
                  sx={{ alignItems: "start", "&:hover": { bgcolor: `rgba(0, 0, 0, 0.04)` } }}
                  aria-controls={`vertical-tabpanel-${i}`}
                />
              ))}
          </Tabs>
        )}

        <TabPanel value={tabIndex} index={0} children={<MarketInsights />} />
        <TabPanel value={tabIndex} index={1} children={<OrderInsights />} />
        <TabPanel value={tabIndex} index={2} children={<ProductInsights />} />
        <TabPanel value={tabIndex} index={3} children={<CustomerInsights />} />
        <TabPanel value={tabIndex} index={4} children={<InvoiceInsights />} />
      </DelimitBox>
    </Box>
  );
};

export default InsightsV2;
