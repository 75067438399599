import { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";

import { AlertTypeEnum, useAlert } from "../../../providers/AlertProvider";
import { useUser } from "../../../providers/UserProvider";
import { postApi } from "../../../service";
import TrendInfoCard from "../../common/card/TrendInfoCard";
import { IWatchForm } from "../productInsights";

const AveragePayments = ({ onSearch, formData }: { onSearch: boolean; formData: IWatchForm }) => {
  const { control } = useForm();
  const watchForm = useWatch({ control });
  const [paymentValue, setPaymentValue] = useState<{ averagePaymentTurnAroundTime: string } | null>(null);
  const { addAlert } = useAlert();
  const { user } = useUser();

  const dataFetch = async () => {
    try {
      let res = await postApi(`${process.env.REACT_APP_POST}`, {
        api_name: "InvoiceInsightForAveragePaymentTurnAroundTime",
        org: process.env.REACT_APP_ORG,
        sector: process.env.REACT_APP_SECTOR,
        countries: user.countries,
        ...formData,
      });

      const { data, status, message } = res.data;

      if (!status) {
        setPaymentValue(null);
        // addAlert({ message, type: AlertTypeEnum.ERROR });
      } else {
        setPaymentValue(data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    dataFetch();
  }, [watchForm, onSearch]);

  return (
    <TrendInfoCard
      isTrendingUp={true}
      content="Average payment turned around by customer"
      value={paymentValue?.averagePaymentTurnAroundTime ?? "0"}
    />
  );
};

export default AveragePayments;
