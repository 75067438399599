import { autocompleteClasses, Box, styled } from "@mui/material";

export const InputWrapper = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.mode === "dark" ? "#434343" : "#d9d9d9"}`,
  backgroundColor: `${theme.palette.mode === "dark" ? "#141414" : "#fff"}`,
  borderRadius: 1,
  padding: "1px",
  display: "flex",
  flexWrap: "wrap",
  marginTop: "8px",

  "&:hover": {
    borderColor: theme.palette.mode === "dark" ? "#177ddc" : "#D8D8D8",
    // borderColor: "#D8D8D8",
    backgroundColor: "#F9F9F9",
  },

  "&.focused": {
    border: "1px solid #0C8CAA",
  },

  "& input": {
    backgroundColor: theme.palette.mode === "dark" ? "#141414" : "#fff",
    color: theme.palette.mode === "dark" ? "rgba(255,255,255,0.65)" : "rgba(0,0,0,.85)",
    height: "30px",
    boxSizing: "border-box",
    padding: "4px 6px",
    width: 0,
    minWidth: "30px",
    flexGrow: 1,
    border: 0,
    margin: 0,
    outline: 0,
  },
}));

export const Listbox = styled("ul")(
  ({ theme }) => `
    width: 300px;
    margin: 2px 0 0;
    padding: 0;
    position: absolute;
    list-style: none;
    background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
    overflow: auto;
    max-height: 250px;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 1;
  
    & li {
      padding: 5px 12px;
      display: flex;
      cursor: pointer;

      :hover {
        background-color : #D8D8D8;
      }
  
      & span {
        flex-grow: 1;
      }
  
      & svg {
        color: transparent;
      }
    }
  
    & li[aria-selected='true'] {
      background-color: ${theme.palette.mode === "dark" ? "#2b2b2b" : "#fafafa"};
      font-weight: 600;
  
      & svg {
        color: #1890ff;
      }
      :hover {
        background-color : #D8D8D8;
      }
    }
  
    & li.${autocompleteClasses.focused} {
      background-color: ${theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"};
      cursor: pointer;
  
      & svg {
        color: currentColor;
      }
      :hover {
        background-color : #D8D8D8;
      }
    }
  `
);

export const StyledTag = styled("div")(
  ({ theme }) => `
        display: flex;
        align-items: center;
        height: 24px;
        margin: 2px;
        line-height: 22px;
        background-color: ${theme.palette.mode === "dark" ? "rgba(255,255,255,0.08)" : "#fafafa"};
        border: 1px solid ${theme.palette.mode === "dark" ? "#303030" : "#e8e8e8"};
        border-radius: 2px;
        box-sizing: content-box;
        padding: 0 4px 0 10px;
        outline: 0;
        overflow: hidden;
      
        &:focus {
          border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
          background-color: ${theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"};
        }
      
        & span {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      
        & svg {
          font-size: 12px;
          cursor: pointer;
          padding: 4px;
        }
      `
);
