/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { AlertTypeEnum, useAlert } from "../../../providers/AlertProvider";
import { postApi } from "../../../service";
import { useUser } from "../../../providers/UserProvider";
import { countryDetails } from "../../common/constants/countries";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { IWatchForm } from "../productInsights";

const MaxTurnaround = ({ onSearch, formData }: { onSearch: boolean; formData: IWatchForm }) => {
  const { t } = useTranslation();
  const { control } = useForm();
  const watchForm = useWatch({ control });
  const { addAlert } = useAlert();
  const { user } = useUser();

  const dateFormat = countryDetails[user.countries[0]].dateFormat;

  const [turnAroundTime, setTurnAroundTime] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const dataFetch = async () => {
    try {
      setIsLoading(true);
      let res = await postApi(`${process.env.REACT_APP_POST}`, {
        api_name: "InvoiceInsightForTopCustomersWithTurnAroundTime",
        org: process.env.REACT_APP_ORG,
        sector: process.env.REACT_APP_SECTOR,
        countries: user.countries,
        ...formData,
      });

      const { data, status, message } = res.data;

      if (!status) {
        setTurnAroundTime([]);
        // addAlert({ message, type: AlertTypeEnum.ERROR });
      } else {
        setTurnAroundTime(data);
      }
    } catch (err) {
      console.error(err);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    dataFetch();
  }, [watchForm, onSearch]);

  return (
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{t("NO")}</TableCell>
            <TableCell align="left">{t("CUSTOMER")}</TableCell>
            <TableCell align="left">Turnaround Time(In Days)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {turnAroundTime.map(({ soldTo, name, totalTurnAroundTime }, index) => (
            <TableRow key={soldTo} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell component="th" scope="row">
                {index + 1}
              </TableCell>
              <TableCell align="left">{name || soldTo}</TableCell>
              <TableCell align="left">{totalTurnAroundTime}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MaxTurnaround;
