import { Input, Select, TextField, styled, ListItemText, Button } from "@mui/material";

export const CustomListItemText = styled(ListItemText)(() => ({
  "& span": {
    lineHeight: "0px",
  },
}));

export const DropNTextCustomInputTextField = styled(Input)(() => ({
  width: "100%",
  "& input": {
    padding: "8px 10px",
  },
}));

export const CustomInputTextField = styled(Input)(({ theme }) => ({
  marginTop: "8px",
  height: "34px",
  width: "100%",
  "& input": {
    padding: "8px 10px",
  },
  "&:before": {
    border: `1px solid #C6C6C6`,
    borderRadius: "4px",
    height: "34px",
  },
  "&:after": {
    border: `1px solid ${theme.palette.primary.main} !important`,
    borderRadius: "4px",
    height: "34px",
  },
  "&:hover": {
    "&:before": {
      border: `1px solid grey !important`,
      borderRadius: "4px",
    },
  },
  "&.Mui-selected": {
    color: theme.palette.text.primary,
  },
}));

export const CustomInputDate = styled(TextField)(() => ({
  marginTop: "5px",
  height: "34px",
  "& input": {
    padding: "8px 10px",
  },
  "& label": {
    display: "none",
  },
  "& legend": {
    display: "none",
  },
  "& fieldset": {
    height: "34px",
    top: "3px",
  },
  "&.Mui-focused": {},
  "&.MuiInputBase-formControl": {
    "& input": {
      padding: "8px 10px",
    },
  },
}));

export const CustomMultiSelectDropdown = styled(Select)(() => ({
  marginTop: "8px",
  height: "34px",
  width: "100%",

  "&:hover": {
    backgroundColor: "#F9F9F9",
  },
  "& legend": {
    display: "none",
  },

  "&.MuiOutlinedInput-root": {
    "& fieldset": {
      height: "34px",
      top: 0,
    },
    "&:hover fieldset": {
      borderColor: "#D8D8D8",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #0C8CAA",
    },
  },
}));

export const CustomDateSelectButton = styled(Button)(() => ({
  marginTop: "8px",
  height: "34px",
  borderColor: "#d9d9d9",

  "&:hover": {
    backgroundColor: "#F9F9F9",
  },
  "& legend": {
    display: "none",
  },

  "&.MuiOutlinedInput-root": {
    "& fieldset": {
      height: "34px",
      top: 0,
    },
    "&:hover fieldset": {
      borderColor: "#D8D8D8",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #0C8CAA",
    },
  },
}));
