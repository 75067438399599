import { useState } from "react";
import { Divider, IconButton, Stack, Typography } from "@mui/material";
import { FilterAltOffOutlined, FilterAltOutlined } from "@mui/icons-material";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import BasicHeader from "../../common/BasicHeader";
import OrderFranchiseChart from "./OrderFranchiseChart";
import OrderLimit from "./OrderLimit";
import PurchaseTrend from "./PurchaseTrend";
import { countryDetails } from "../../common/constants/countries";
import OrderQuantity from "./OrderQuantity";
import InsightFilter from "../InsightFilter";
import { useUser } from "../../../providers/UserProvider";
import BasicTabs from "../../common/BasicTabs";
import { IWatchForm } from "../productInsights";

const OrderInsights = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const formDetails = useForm({
    defaultValues: {
      duration: "1 Month",
      soldTos: user.soldToDetails ? user.soldToDetails.map(({ accountId }) => accountId) : [],
    },
  });
  const watchForm: IWatchForm = useWatch({ control: formDetails.control });

  const customInsightMarkets = ["AU", "NZ"];

  const [onSearch, setOnSearch] = useState(false);
  const [purchaseTrendMsg, setPurchaseTrendMsg] = useState("");
  const [isFilterVisible, setIsFilterVisible] = useState<boolean>(false);

  const calculateDuration = () => {
    const customeChinaObj = {
      "1 Month": "一个月",
      "3 Month": "三个月",
      "6 Month": "六个月",
    };

    if (watchForm.duration === "custom") {
      const startDate = dayjs(watchForm?.startDate);
      const endDate = dayjs(watchForm?.endDate);
      const diffInDays = endDate.diff(startDate, "day");

      if (user.countries[0] === "CN") {
        return diffInDays + "天";
      }
      return diffInDays + " Day(s)";
    } else {
      if (user.countries[0] === "CN") {
        return customeChinaObj[watchForm.duration || "1 Month"];
      }
      if (watchForm.duration === "3 Month" || watchForm.duration === "6 Month") {
        return watchForm.duration + "s";
      } else {
        return watchForm.duration;
      }
    }
  };

  const toggleSearch = () => {
    setOnSearch(!onSearch);
  };

  const purchaseTrendDuration = () => {
    const duration = calculateDuration();
    setPurchaseTrendMsg(
      `${t("VIEWING_PERCHASE_TREND")} ${duration}  ${
        watchForm?.franchises?.length || watchForm?.soldTos?.length
          ? `${t("SELECTED_FRANCHISE_CUSTOMER")}`
          : `${t("FOR_ALL_FRANCHISES")}`
      }`
    );
  };

  const loadDefaultValues = (res) => {
    if (!res.data.status && watchForm.duration === "1 Month" && !watchForm.franchises && !watchForm.soldTos) {
      formDetails.setValue("duration", "3 Month");
      toggleSearch();
      purchaseTrendDuration();
      return true;
    } else {
      purchaseTrendDuration();
      return false;
    }
  };
  return (
    <>
      <BasicHeader
        title={t("ORDER_INSIGHTS")}
        customStyle={{ pb: "20px" }}
        children={
          <IconButton
            aria-label="filter"
            onClick={() => setIsFilterVisible((prev) => !prev)}
            sx={{ display: { xs: "inherit", sm: "none" } }}
          >
            {isFilterVisible ? <FilterAltOffOutlined /> : <FilterAltOutlined color="primary" />}
          </IconButton>
        }
      />
      {!isFilterVisible && <InsightFilter toggleSearch={toggleSearch} formDetails={formDetails} formData={watchForm} />}

      <Stack direction={{ xs: "column", sm: "row" }} gap={6}>
        <OrderFranchiseChart onSearch={onSearch} formData={watchForm} loadDefaultValues={loadDefaultValues} />
        <Stack flex={1} sx={{ height: 535 }}>
          <Typography variant="h2" mt={2}>
            {t("PURCHASE_TREND")}
          </Typography>
          <BasicTabs
            data={[{ label: t("ORDER_VALUE") }, { label: t("ORDER_QUANTITY") }]}
            components={[
              <PurchaseTrend onSearch={onSearch} formData={watchForm} />,
              <OrderQuantity onSearch={onSearch} formData={watchForm} />,
            ]}
            currentTab={0}
          />
          <Typography mt={13}>{purchaseTrendMsg}</Typography>
        </Stack>
      </Stack>

      <Divider sx={{ mt: 6, display: { xs: "none", sm: "block" } }} />

      {!customInsightMarkets?.some((e) => countryDetails[e].countryCode === user.countries[0]) ? (
        <></>
      ) : (
        <>
          <OrderLimit onSearch={onSearch} formData={watchForm} />
        </>
      )}
    </>
  );
};

export default OrderInsights;
