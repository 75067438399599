/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import {
  Chart as ChartJS,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PluginChartOptions,
} from "chart.js";
import { Pie } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

import { AlertTypeEnum, useAlert } from "../../../providers/AlertProvider";
import { useUser } from "../../../providers/UserProvider";
import { postApi } from "../../../service";
import {
  orderInsightPieData,
  orderInsightPieOptions,
  orderInsightPlugins,
} from "../constants";
import Loader from "../../common/loader/Loader";
import { countryDetails } from "../../common/constants/countries";
import { currencyFormatter } from "../../../helper";
import { useWindowDimensions } from "../../../hooks";

ChartJS.register(LinearScale, ArcElement, Title, Tooltip, Legend);

const OrderFranchiseChart = ({ formData, onSearch, loadDefaultValues }) => {
  const { t } = useTranslation();
  const { windowWidth } = useWindowDimensions();
  const { user } = useUser();
  const { addAlert } = useAlert();
  const currency = countryDetails[user.countries[0]].currency;

  const [resData, setResData] = useState<any>({});
  const [isLoading, setIsLoading] = useState(true);
  const [topFranchise, setTopFranchise] = useState<{
    franchiseName: string;
    netValue: string;
    franchiseCode: string;
  } | null>(null);

  const dataFetch = async () => {
    try {
      setIsLoading(true);
      const res = await postApi(`${process.env.REACT_APP_POST}`, {
        api_name: "OrderInsightBySaleForFranchise",
        org: process.env.REACT_APP_ORG,
        sector: process.env.REACT_APP_SECTOR,
        countries: user.countries,
        ...formData,
      });

      const { data, status, message } = res.data;
      if (loadDefaultValues(res)) {
        return;
      }

      if (!status) {
        // setResData({});
        // added alert in './PurchaseTrend.tsx'
        // if (formData.duration !== "1 Month")
        // addAlert({ message:"", type: AlertTypeEnum.ERROR });
      } else {
        const labels = data.map(
          ({ franchiseName, franchiseCode }) =>
            `${franchiseName}(${franchiseCode})`
        );

        setTopFranchise(
          data.reduce((prev, current) =>
            prev && Number(prev.netValue) > Number(current.netValue)
              ? prev
              : current
          )
        );

        setResData({
          ...orderInsightPieData,
          labels,
          datasets: [
            {
              ...orderInsightPieData.datasets[0],
              data: data.map(({ netValue }) => netValue),
            },
          ],
        });
      }
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    dataFetch();
  }, [onSearch]);

  const options = {
    ...orderInsightPieOptions,
    ...(orderInsightPlugins(currency, windowWidth < 600) as PluginChartOptions<"pie">),
  };

  return (
    <Box flex={1}>
      <Typography variant="h2" my={2}>
        {t("ORDER_VALUE_PLACED_FRANCHISE")}
      </Typography>

      {isLoading ? (
        <Loader />
      ) : resData.datasets ? (
        <>
          <Box sx={{ height: { xs: "auto", sm: 500 } }}>
            <Pie data={resData} options={options} />
          </Box>
          {topFranchise && (
            <Typography variant="h3" sx={{ m: 0, whiteSpace: { xs: "normal", sm: "nowrap" } }}>
              {topFranchise.franchiseName}({topFranchise.franchiseCode})
              <Typography component="span">
                {" "}
                {t("FRANCHISES_HAS_PLACED_HIGHEST_ORDER")}{" "}
              </Typography>
              {currency} {currencyFormatter(Number(topFranchise.netValue))}
            </Typography>
          )}
        </>
      ) : (
        <>{t("NO_DATA_FOUND")}</>
      )}
    </Box>
  );
};

export default OrderFranchiseChart;
